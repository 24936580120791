@use "base";

section.section-E {
  background-color: base.$secondary-color;
  color: base.$light-color;
  display: flex;
  justify-content: space-evenly;
  .star {
    width: 5em;
    top: 10%;
    left: 30%;
  }
  div.title-wrapper {
    width: 30em;
  }
  div.inner {
    max-width: 60em;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
  }
  h3 {
    font-weight: 900;
    font-size: 4em;
    text-shadow: 0 0.15em 0 rgba(0, 0, 0, 1);
    margin-bottom: 0.5em;
  }
  h4 {
    font-size: 1.5em;
    font-weight: 300;
  }
  .strong {
    position: relative;
    top: 0.1em;
    height: 1em;
    align-items: flex-end;
    display: inline-flex;
    background-color: base.$secondary-accent-color;
    border-radius: 9999px;
    height: 0.5em;
    strong {
      font-weight: 900;
      position: relative;
      top: -0.1em;
    }
  }
  ul.feat-promises {
    /*
      Using inline-grid (instead of just "grid") allows for text-align to
      center the grid container (instead of having to muck about with relative
      negative margins).
    */
    display: grid;
    padding: 0;
    width: 30em;
    height: 30em;
    gap: 2em 1em;
    margin-top: 2em;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);
    grid-template-areas:
      "a a a a a a 1 1"
      "a a a a a a 1 1"
      "a a a a a a b b"
      "a a a a a a b b"
      "c c c c d d d d"
      "c c c c d d d d"
      "c c c c d d d d"
      "3 3 3 3 d d d d";

    /*
      Direct descendants only – to prevent messing with the li-s of the nested
      "ul.feat-promises" further down...
    */
    > li {
      list-style-type: none;
      margin: 0;

      /*
        Stretch all direct descendants to their full grid-cell size so proper
        full-size boxes are drawn.
      */
      > * {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        margin: 0px;
        // This makes the contents of the grid cells vertically centered
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
      }

      div {
        color: base.$light-color;
        box-shadow: 0 0.3em base.$light-color;
        border: none;
        border-radius: 1em;
        box-sizing: border-box;
        text-align: center;
        p,
        a {
          font-size: 1.5em;
          line-height: 1.25em;
          padding: 0.8em;
          text-decoration: none;
          font-weight: bold;
        }
      }

      div.blue {
        text-wrap: wrap;
        background-color: base.$tertiary-color;
        border: solid 0.6em base.$dark-color;
        font-size: 1em;
        h4 {
          font-size: 2em;
          font-weight: 600;
        }
      }
      div.yellow {
        background-color: base.$primary-color;
        text-align: left;
        font-size: 0.7em;
        color: base.$dark-color;
        // If a div also has the class small:
        &.small {
          font-size: 0.6em;
        }
      }

      /*
        Increase border-radius for "dark" div-s that contain an a-element to
        make them appear like fully rounded buttons.
      */
      div.dark:has(> a) {
        border-radius: 2.5em;
      }
      /*
        Stretch the "overflow"-"dark"-div outside of its grid cell (this is why
        the containing "section" has a slightly higher bottom margin).
      */
      div.dark.overflow {
        height: 120%;
        position: relative;
      }

      ul.feat-promises {
        height: auto;
        display: inline;
        padding: 0;
        text-align: center;
        > li {
          list-style-type: none;
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          width: 2em;
          height: 2em;
          border-radius: 3em;
          border: solid 0.1em base.$light-color;
          margin: 0.2em;
          a {
            text-decoration: none;
            color: base.$dark-color;
          }
        }
      }
    }

    /*
      For the promises no wrapper is required (as they're not in a box with a
      border and/or background – so instead we use the ":has()" pseudo-selector
      to apply the "vertical centring"-approach directly to the the li-element
      containing the promises-ul.
    */
    > li:has(> ul.feat-promises) {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
    }

    > li:nth-child(1) {
      grid-area: a;
    }
    > li:nth-child(2) {
      grid-area: b;
    }
    > li:nth-child(3) {
      grid-area: c;
    }
    > li:nth-child(4) {
      grid-area: d;
    }
  }
}
section.section-E + svg.wave {
  background-color: black;
  * {
    fill: base.$secondary-color;
  }
}
// On mobile devices, unset the width of the title wrapper, and set the width and height of the feat-promises element to 40em.
@media (max-width: 768px) {
  section.section-E {
    div.title-wrapper {
      width: 40em;
    }
    ul.feat-promises {
      width: 40em;
      height: 40em;
      li {
        div.blue {
          h4 {
            font-weight: 800;
            font-size: 2.5em;
          }
          p {
            font-weight: 600;
            font-size: 2em;
          }
        }
        div.yellow {
          font-size: 1.2em;
          &.small {
            font-size: 0.9em;
          }
        }
      }
    }
  }
}
