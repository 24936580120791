@use "base";

section.section-B {
  background-color: base.$dark-color;
  padding: 4em 4em 5em 4em;
  color: base.$light-color;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  .strong {
    position: relative;
    top: 0.1em;
    height: 1em;
    align-items: flex-end;
    display: inline-flex;
    background-color: base.$primary-color;
    border-radius: 9999px;
    height: 0.5em;
    strong {
      font-weight: 900;
      position: relative;
      top: -0.1em;
    }
  }
  .video-container {
    display: flex;
    justify-content: center;
    position: relative;
    width: 60em;
    max-width: 80vw;
    video {
      width: 100%;
      margin: 0 auto;
      height: auto;
    }
    button {
      font-size: 2em;
      font-weight: 600;
      border: 0.25em solid base.$dark-color;
      border-radius: 9999px;
      background-color: base.$tertiary-color;
      color: base.$light-color;
      box-shadow: 0 0.25em 0 rgba(255, 255, 255, 1);
      padding: 1em 2em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    button:hover {
      transform: translate(-50%, -50%) translateY(0.25em);
      box-shadow: none;
      cursor: pointer;
    }
  }
  h3 {
    margin-bottom: 0;
    font-weight: 900;
    font-size: 4em;
    text-shadow: 0 0.15em 0 rgba(0, 0, 0, 1);
    em {
      color: base.$secondary-color;
      font-style: normal;
    }
  }
  h4 {
    color: lightgray;
    font-size: 2em;
    font-weight: 600;
  }
  video {
    border-radius: 1.5em;
    border: 1em solid base.$light-color;
  }
}

section.section-B + svg.wave {
  background-color: base.$secondary-color;
  * {
    fill: base.$dark-color;
  }
}
