@use "base";

section.section-C {
  padding-top: 10em;
  padding-bottom: 10em;
  background-color: base.$secondary-color;
  color: base.$light-color;
  display: flex;
  justify-content: space-evenly;
  .star {
    width: 5em;
    top: 10%;
    left: 30%;
  }
  div.title-wrapper {
    width: 30em;
  }
  div.testimonials {
    display: flex;
    flex-direction: column;
    width: 30em;
    gap: 1em;
  }
  div.testimonial {
    width: 25em;
    display: flex;
    gap: 1em;
    img {
      max-width: 4em;
      align-self: flex-start; // Aligns the image to the top of the div, and thus prevents the image from being stretched.
      border-radius: 100%;
      box-shadow: 0 0.35em 0 rgba(0, 0, 0, 1);
    }
    img.upper {
      border: 0.4em solid base.$light-color;
    }
    img.lower {
      border: 0.4em solid base.$primary-color;
    }
    div.quote {
      position: relative;
      border: 0.5em solid black;
      background-color: base.$light-color;
      color: black;
      border-radius: 1em;
      padding: 1em;
      box-shadow: 0 0.35em 0 rgba(255, 255, 255, 1);
    }
    div.quote::before {
      content: "";
      position: absolute;
      top: 10px;
      left: -3em;
      border-width: 0.75em 1.5em;
      border-style: solid;
      border-color: transparent #000 transparent transparent;
    }
  }
  div.upper-testimonial {
    // Align it on the right.
    align-self: flex-end;
  }
  div.inner {
    max-width: 60em;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
  }
  h3 {
    font-weight: 900;
    font-size: 4em;
    text-shadow: 0 0.15em 0 rgba(0, 0, 0, 1);
    margin-bottom: 0.5em;
  }
  .strong {
    position: relative;
    top: 0.1em;
    height: 1em;
    align-items: flex-end;
    display: inline-flex;
    background-color: base.$secondary-accent-color;
    border-radius: 9999px;
    height: 0.5em;
    strong {
      font-weight: 900;
      position: relative;
      top: -0.1em;
    }
  }
}

section.section-C + svg.wave {
  background-color: base.$primary-color;
  * {
    fill: base.$secondary-color;
  }
}
@media (max-width: 768px) {
  section.section-C {
    div.title-wrapper {
      width: unset;
    }
  }
}
