@use "base";

$background: base.$primary-color;
$highlight: base.$secondary-color;
$underline: base.$tertiary-color;
$dark: base.$dark-color;
$light: base.$light-color;

@media (max-width: 1100px) {
  body {
    font-size: 60%;
  }
}

@media (max-width: 700px) {
  body {
    font-size: 45%;
    /*
      Force (centred) content to overflow – all other breakpoints rely on text-
      align to centre the content (which is "chicer", but doesn't allow a nice
      overflow effect).
    */
    section.section-D {
      padding: 4em 0 5em 0;
      overflow-x: hidden;
      /*
        This goes far beyond gold-plating, but it's helpful to demonstrate the
        power of the overall approach... 😇
      */
      ul.feat-visuals {
        margin: 0 -50%;
        grid-template-areas:
          "5 5 5 5 5 5 5 c c c c 7"
          "a a a b b b b c c c c 7"
          "a a a b b b b c c c c 7"
          "a a a b b b b d d e e 7"
          "a a a b b b b d d e e 7"
          "a a a b b b b d d e e 7"
          "a a a b b b b d d e e 7"
          "a a a g g g h h h h i i"
          "1 f f g g g h h h h i i"
          "1 f f g g g h h h h i i"
          "1 f f g g g h h h h i i"
          "1 f f g g g h h h h 2 2"
          "1 f f 4 4 4 h h h h 2 2";
      }
    }
  }
}

body {
  background: $dark;
}

section.section-D {
  .small-blue-wiggle {
    width: 8em;
    bottom: 10em;
    right: 30em;
  }
  .big-blue-wiggle {
    width: 10em;
    top: 25%;
    left: 30%;
  }
  color: base.$dark-color;
  background-color: $background;
  padding: 4em 4em 5em 4em;
  text-align: center;
  em {
    color: base.$secondary-color;
    font-style: normal;
  }

  h3 {
    font-weight: 900;
    font-size: 4em;
    text-shadow: 0 0.15em 0 rgba(255, 255, 255, 1);
    margin-bottom: 0.5em;
  }
  h4 {
    font-size: 2em;
    margin-top: 0;
  }
  .strong {
    position: relative;
    top: 0.1em;
    height: 1em;
    align-items: flex-end;
    display: inline-flex;
    background-color: base.$secondary-color;
    border-radius: 9999px;
    height: 0.5em;
    strong {
      font-weight: 900;
      position: relative;
      top: -0.1em;
    }
  }

  ul.feat-visuals {
    /*
      Using inline-grid (instead of just "grid") allows for text-align to
      center the grid container (instead of having to muck about with relative
      negative margins).
    */
    display: inline-grid;
    padding: 0;
    width: 60em;
    height: 35em;
    gap: 2em 1em;
    margin-top: 2em;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(13, 1fr);
    grid-template-areas:
      "5 5 5 5 5 5 5 c c c c 7"
      "a a a b b b b c c c c 7"
      "a a a b b b b c c c c 7"
      "a a a b b b b d d e e 7"
      "a a a b b b b d d e e 7"
      "a a a b b b b d d e e 7"
      "a a a b b b b d d e e 7"
      "a a a g g g h h h h i i"
      "1 f f g g g h h h h i i"
      "1 f f g g g h h h h i i"
      "1 f f g g g h h h h i i"
      "1 f f g g g h h h h 2 2"
      "1 f f 4 4 4 h h h h 2 2";

    /*
      Direct descendants only – to prevent messing with the li-s of the nested
      "ul.feat-socials" further down...
    */
    > li {
      list-style-type: none;
      margin: 0;

      /*
        Stretch all direct descendants to their full grid-cell size so proper
        full-size boxes are drawn.
      */
      > * {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        margin: 0px;
        // This makes the contents of the grid cells vertically centered
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
      }

      figure {
        border: solid 0.6em $light;
        border-radius: 1em;
        /*
          Ensure border is rendered _inside_ the box – otherwise the borders
          would break up the proper sizing of the grid layout.
        */
        box-sizing: border-box;
        background-color: $light;
        box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);
        img {
          object-fit: cover;
          border-radius: inherit;
          width: 100%;
          height: 100%;
        }
      }

      div {
        color: $light;
        box-shadow: 0 0.3em $light;
        border: none;
        border-radius: 1em;
        box-sizing: border-box;
        text-align: center;
        p,
        a {
          font-size: 1.5em;
          line-height: 1.25em;
          padding: 0.8em;
          text-decoration: none;
          font-weight: bold;
        }
      }

      div.blue {
        background-color: $underline;
        border: solid 0.6em $dark;
        font-size: 1.5em;
      }
      div.purple {
        background-color: $highlight;
        text-align: left;
        font-size: 0.85em;
      }
      div.dark {
        background-color: $dark;
        p {
          font-size: 2em;
        }
        a {
          font-size: 1.2em;
          color: $light;
        }
      }

      /*
        Increase border-radius for "dark" div-s that contain an a-element to
        make them appear like fully rounded buttons.
      */
      div.dark:has(> a) {
        border-radius: 2.5em;
      }
      /*
        Stretch the "overflow"-"dark"-div outside of its grid cell (this is why
        the containing "section" has a slightly higher bottom margin).
      */
      div.dark.overflow {
        height: 120%;
        position: relative;
      }

      ul.feat-socials {
        height: auto;
        display: inline;
        padding: 0;
        text-align: center;
        > li {
          list-style-type: none;
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          width: 2em;
          height: 2em;
          border-radius: 3em;
          border: solid 0.1em $light;
          margin: 0.2em;
          a {
            text-decoration: none;
            color: $dark;
          }
        }
      }
    }

    /*
      For the socials no wrapper is required (as they're not in a box with a
      border and/or background – so instead we use the ":has()" pseudo-selector
      to apply the "vertical centring"-approach directly to the the li-element
      containing the socials-ul.
    */
    > li:has(> ul.feat-socials) {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
    }

    > li:nth-child(1) {
      grid-area: a;
    }
    > li:nth-child(2) {
      grid-area: b;
    }
    > li:nth-child(3) {
      grid-area: c;
    }
    > li:nth-child(4) {
      grid-area: d;
    }
    > li:nth-child(5) {
      grid-area: e;
    }
    > li:nth-child(6) {
      grid-area: f;
    }
    > li:nth-child(7) {
      grid-area: g;
    }
    > li:nth-child(8) {
      grid-area: h;
    }
    > li:nth-child(9) {
      grid-area: i;
    }
  }
}
section.section-D + svg.wave {
  background-color: base.$secondary-color;
  * {
    fill: $background;
  }
}
