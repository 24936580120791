@use "base";

$background: base.$primary-color;
$highlight: base.$secondary-color;
$underline: base.$tertiary-color;
$dark: base.$dark-color;
$light: base.$light-color;

@media (max-width: 1100px) {
  body {
    font-size: 60%;
  }
}

@media (max-width: 700px) {
  body {
    font-size: 45%;
    div.sticky-c2a {
      a {
        font-size: 1.5em;
      }
    }
    section.section-A {
      padding: 4em 0 5em 0;
      overflow-x: hidden;
      height: 90vh;

      nav.desktop {
        display: none;
      }

      nav.mobile {
        display: inline-flex;
      }

      ul.feat-visuals {
        height: 55em;
        margin: 0 -50%;
        grid-template-areas:
          "0 0 0 b b b b b j j j 1"
          "0 0 0 b b b b b c c d d"
          "f f f b b b b b c c d d"
          "f f f b b b b b c c d d"
          "f f f a a a g g g g h h"
          "3 3 3 a a a g g g g h h"
          "3 3 3 a a a g g g g h h"
          "5 e e e e e 2 2 2 i i 4";
      }
    }
  }
}

body {
  background: $dark;
}

section.section-A {
  min-height: 80vh;
  color: base.$dark-color;
  background-color: $background;
  padding: 4em 4em 5em 4em;
  text-align: center;
  .tiny-star {
    width: 2em;
    bottom: 0;
    left: 40em;
  }
  .small-star {
    width: 4em;
    top: 20em;
    left: 20em;
  }
  .blue-wiggle-vert {
    path {
      fill: base.$secondary-accent-color;
    }
    // Rotate 90 degrees.
    transform: rotate(90deg);
    width: 6em;
    top: 20em;
    right: 20em;
  }
  .blue-wiggle-horiz {
    path {
      fill: base.$secondary-accent-color;
    }
    width: 6em;
    bottom: 5em;
    left: 25em;
  }
  .purple-wiggles {
    path {
      fill: base.$primary-accent-color;
    }
    width: 30em;
    bottom: 5%;
    right: 20%;
  }
  .big-star {
    width: 10em;
    top: 10em;
    right: 10em;
  }
  svg.wave {
    * {
      fill: $background;
    }
  }
  nav.mobile {
    width: 90vw;
    display: none;
    align-items: center;
    justify-content: space-between;
    div.mobile-icons {
      gap: 1em;
      display: flex;
      justify-content: center;
    }
    div.nav-item-container {
      div {
        text-align: left;
      }
    }

    img {
      max-width: 4em;
    }
    button.dark {
      font-size: 1.5em;
      font-weight: 600;
      cursor: pointer;
      color: $light;
      padding: 1em 2em;
      border-radius: 9999px;
      box-shadow: 0 0.25em 0 rgba(255, 255, 255, 1);
      background-color: $dark;
    }
  }
  ul {
    button.light {
      display: flex;
      align-items: center;
      font-size: 1.25em;
      font-weight: 400;
      cursor: pointer;
      text-decoration: none;
      color: $dark;
      text-align: center;
      padding: 0.5em 0.5em;
      border: 0 solid $dark;
      border-radius: 9999px !important;
      box-shadow: 0 0.25em 0 rgba(0, 0, 0, 1);
      background-color: $light;
    }
  }
  nav.desktop {
    img {
      max-height: 3em;
    }
    max-width: 90vw;
    width: 60em;
    place-items: center;
    justify-content: space-around;
    display: inline-flex;
    a {
      text-decoration: none;
      color: inherit;
    }
    a:hover {
      text-decoration: underline;
    }
    button.dark {
      font-size: inherit;
      font-weight: 600;
      cursor: pointer;
      color: $light;
      padding: 1em 2em;
      border-radius: 9999px;
      box-shadow: 0 0.25em 0 rgba(255, 255, 255, 1);
      background-color: $dark;
    }
    button.dark:hover {
      transform: translateY(0.25em);
      box-shadow: none;
    }
    ul {
      display: flex;
      justify-content: space-around;
      list-style-type: none;
      li {
        margin: 0 2em;
      }
    }
  }
  em {
    color: base.$primary-accent-color;
    font-style: normal;
  }

  h3 {
    font-weight: 900;
    font-size: 4em;
    text-shadow: 0 0.15em 0 rgba(255, 255, 255, 1);
    margin-bottom: 0.5em;
  }
  h4 {
    font-size: 2em;
    margin-top: 0;
  }

  .strong {
    position: relative;
    top: 0.1em;
    height: 1em;
    align-items: flex-end;
    display: inline-flex;
    background-color: base.$secondary-accent-color;
    border-radius: 9999px;
    height: 0.5em;
    strong {
      font-weight: 900;
      position: relative;
      top: -0.1em;
    }
  }

  ul.feat-visuals {
    /*
      Using inline-grid (instead of just "grid") allows for text-align to
      center the grid container (instead of having to muck about with relative
      negative margins).
    */
    display: inline-grid;
    padding: 0;
    width: 60em;
    height: 35em;
    gap: 2em 1em;
    margin-top: 2em;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(8, 1fr);
    grid-template-areas:
      "0 0 0 b b b b b j j j 1"
      "a a a b b b b b c c d d"
      "a a a b b b b b c c d d"
      "a a a b b b b b c c d d"
      "a a a f f f g g g g h h"
      "a a a f f f g g g g h h"
      "a a a f f f g g g g h h"
      "e e e e e e 2 2 2 2 i i";

    /*
      Direct descendants only – to prevent messing with the li-s of the nested
      "ul.feat-socials" further down...
    */
    > li {
      list-style-type: none;
      margin: 0;

      /*
        Stretch all direct descendants to their full grid-cell size so proper
        full-size boxes are drawn.
      */
      > * {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        margin: 0px;
        // This makes the contents of the grid cells vertically centered
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
      }

      figure {
        border: solid 0.6em $light;
        border-radius: 1em;
        /*
          Ensure border is rendered _inside_ the box – otherwise the borders
          would break up the proper sizing of the grid layout.
        */
        box-sizing: border-box;
        background-color: $light;
        box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);
        img {
          object-fit: cover;
          border-radius: inherit;
          width: 100%;
          height: 100%;
        }
      }

      div {
        color: $light;
        box-shadow: 0 0.3em $light;
        border: none;
        border-radius: 1em;
        box-sizing: border-box;
        text-align: center;
        p,
        a {
          font-size: 1.5em;
          line-height: 1.25em;
          padding: 0.8em;
          text-decoration: none;
          font-weight: bold;
        }
      }

      div.blue {
        background-color: $underline;
        border: solid 0.6em $dark;
        font-size: 1.5em;
      }
      div.purple {
        background-color: base.$primary-accent-color;
        text-align: left;
        font-size: 1.1em;
      }
      div.dark {
        background-color: $dark;
        p {
          font-size: 2em;
        }
        a {
          font-size: 1.2em;
          color: $light;
        }
      }

      /*
        Increase border-radius for "dark" div-s that contain an a-element to
        make them appear like fully rounded buttons.
      */
      div.dark:has(> a) {
        border-radius: 2.5em;
      }
      /*
        Stretch the "overflow"-"dark"-div outside of its grid cell (this is why
        the containing "section" has a slightly higher bottom margin).
      */
      div.dark.overflow {
        height: 120%;
        position: relative;
      }

      ul.feat-socials {
        height: auto;
        display: inline;
        padding: 0;
        text-align: center;
        li:hover {
          background-color: base.$dark-color;
          svg {
            fill: base.$primary-color;
          }
        }
        > li {
          background-color: base.$primary-color;
          list-style-type: none;
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          width: 2em;
          height: 2em;
          border-radius: 3em;
          border: solid 0.1em $light;
          margin: 0.2em;
          a {
            display: flex;
            justify-content: center;
            text-decoration: none;
            color: $dark;
          }
        }
      }
    }

    /*
      For the socials no wrapper is required (as they're not in a box with a
      border and/or background – so instead we use the ":has()" pseudo-selector
      to apply the "vertical centring"-approach directly to the the li-element
      containing the socials-ul.
    */
    > li:has(> ul.feat-socials) {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
    }

    > li:nth-child(1) {
      grid-area: a;
    }
    > li:nth-child(2) {
      grid-area: b;
    }
    > li:nth-child(3) {
      grid-area: c;
    }
    > li:nth-child(4) {
      grid-area: d;
    }
    > li:nth-child(5) {
      grid-area: e;
    }
    > li:nth-child(6) {
      grid-area: f;
    }
    > li:nth-child(7) {
      grid-area: g;
    }
    > li:nth-child(8) {
      grid-area: h;
    }
    > li:nth-child(9) {
      grid-area: i;
    }
    > li:nth-child(10) {
      grid-area: j;
    }
  }
}

section.section-A + svg.wave {
  * {
    fill: $background;
  }
}
div.backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.5em);
  z-index: 5;
}
div.mobile-menu-modal.active {
  display: flex;
}
div.backdrop.active {
  display: block;
}
div.mobile-menu-modal {
  display: none;
  flex-direction: row;
  border-radius: 1em;
  padding: 2em;
  width: 85vw;
  background-color: $light;
  position: fixed;
  top: 2%;
  left: 2%;
  z-index: 10;
  text-align: left;
  img {
    width: 4em;
    position: absolute;
    right: 2em;
    top: 2em;
  }
  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    li {
      margin: 1em 0;
    }
    a {
      text-decoration: none;
      font-size: 2em;
      font-weight: bold;
      color: inherit;
    }
  }
}
div.sticky-c2a {
  display: flex;
  align-items: center;
  position: fixed;
  padding: 0.75em 1em 0.75em 0.75em;
  bottom: calc(env(safe-area-inset-bottom) + 3em);
  right: 3em;
  border-radius: 9999px;
  background-color: base.$dark-color;
  svg {
    fill: base.$primary-color;
  }
  z-index: 15;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
div.sticky-c2a:hover {
  background-color: lighten(base.$dark-color, 5%);
  svg {
    fill: lighten(base.$primary-color, 5%);
  }
}
div.sticky-c2a {
  a {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  position: fixed;
  padding: 0.75em;
  bottom: calc(env(safe-area-inset-bottom) + 3em);
  right: 3em;
  border-radius: 9999px;
  background-color: base.$secondary-color;
  color: white;
  z-index: 15;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
div.sticky-c2a:hover {
  // Make background slightly lighter
  background-color: lighten(base.$secondary-color, 5%);
  cursor: pointer;
}
html {
  scroll-behavior: smooth;
}
