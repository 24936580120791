@use "base";

section.section-K {
  color: base.$dark-color;
  background-color: base.$light-color;
  padding: 4em 4em 5em 4em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  .purple-wiggle {
    path {
      fill: base.$tertiary-accent-color;
    }
    width: 10em;
    bottom: 5%;
    left: 60%;
  }
  button {
    box-shadow: 0 0.25em 0 rgba(0, 0, 0, 1);
    color: base.$dark-color;
    font-size: 1em;
    padding: 1em 2em;
    background-color: base.$light-color;
    border-radius: 2.5em;
    cursor: pointer;
  }
  button:hover {
    cursor: pointer;
    transform: translateY(0.25em);
    box-shadow: none;
  }
  h3 {
    font-weight: 900;
    font-size: 4em;
    text-shadow: 0 0.15em 0 rgba(255, 255, 255, 1);
  }
  h4 {
    font-size: 1.5em;
    font-weight: 300;
  }
  .strong {
    position: relative;
    top: 0.1em;
    height: 1em;
    align-items: flex-end;
    display: inline-flex;
    background-color: base.$primary-color;
    border-radius: 9999px;
    height: 0.5em;
    strong {
      font-weight: 900;
      position: relative;
      top: -0.1em;
    }
  }
}
section.section-K + svg.wave {
  background: base.$secondary-color;
  * {
    fill: base.$light-color;
  }
}
