@use "base";

section.section-N {
  padding: 0 4em 5em 4em;
  background-color: base.$light-color;
  color: base.$dark-color;
  text-align: center;
  display: flex;
  justify-content: center;
  div.inner {
    max-width: 60em;
  }
  h3 {
    font-size: 3em;
  }
  div.legal {
    display: flex;
    justify-content: space-evenly;
  }
  div.below-hr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    * {
      flex: 1;
    }
    figure {
      text-align: left;
      margin: 0;
      img {
        max-width: 4em;
      }
    }
  }
  ul {
    list-style-type: none;
  }
  div.feat-buttons {
    display: flex;
    justify-content: center;
    margin: 2em 0;
    button {
      color: base.$dark-color;
      background-color: base.$light-color;
      box-shadow: 0 0.25em 0 rgba(0, 0, 0, 1);
      font-size: 1em;
      margin: 0 1em;
      border-radius: 9999px;
      padding: 1em 2em;
    }
    button:hover {
      cursor: pointer;
      box-shadow: none;
      transform: translateY(0.25em);
    }
  }
  ul.feat-socials {
    display: flex;
    justify-content: center;
    li {
      display: flex;
      justify-content: center;
      max-width: 2.5em;
      max-height: 2.5em;
      margin: 0 1em;
      border-radius: 9999px;
      padding: 1em;
      border: 2px solid base.$light-color;
    }
  }
}
