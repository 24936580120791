@use "base";

section.section-J {
  background-color: base.$primary-color;
  color: base.$dark-color;
  display: flex;
  justify-content: space-evenly;
  .star {
    width: 5em;
    top: 10%;
    left: 30%;
  }
  div.title-wrapper {
    width: 30em;
  }
  div.inner {
    max-width: 60em;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
  }
  h3 {
    font-weight: 900;
    font-size: 4em;
    text-shadow: 0 0.15em 0 rgba(255, 255, 255, 1);
    margin-bottom: 0.5em;
  }
  h4 {
    font-size: 1.5em;
    font-weight: 300;
  }
  .strong {
    position: relative;
    top: 0.1em;
    height: 1em;
    align-items: flex-end;
    display: inline-flex;
    background-color: base.$secondary-accent-color;
    border-radius: 9999px;
    height: 0.5em;
    strong {
      font-weight: 900;
      position: relative;
      top: -0.1em;
    }
  }
  ul.feat-promises {
    li {
      border: 0.5em solid base.$light-color;
      border-radius: 1em;
      img {
        border-radius: 1em;
        object-fit: cover;
      }
    }
    display: grid;
    padding: 0;
    width: 30em;
    height: 30em;
    gap: 2em 1em;
    margin-top: 2em;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-template-areas:
      "1 1 b b b 2"
      "a a b b b c"
      "a a b b b c"
      "a a d d e e"
      "3 3 d d e e"
      "3 3 4 4 5 5";

    /*
      Direct descendants only – to prevent messing with the li-s of the nested
      "ul.feat-promises" further down...
    */
    > li {
      list-style-type: none;
      margin: 0;

      /*
        Stretch all direct descendants to their full grid-cell size so proper
        full-size boxes are drawn.
      */
      > * {
        width: 100%;
        height: 100%;
        margin: 0px;
        // This makes the contents of the grid cells vertically centered
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
      }

      ul.feat-promises {
        height: auto;
        display: inline;
        padding: 0;
        text-align: center;
      }
    }

    /*
      For the promises no wrapper is required (as they're not in a box with a
      border and/or background – so instead we use the ":has()" pseudo-selector
      to apply the "vertical centring"-approach directly to the the li-element
      containing the promises-ul.
    */
    > li:has(> ul.feat-promises) {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
    }

    > li:nth-child(1) {
      grid-area: a;
    }
    > li:nth-child(2) {
      grid-area: b;
    }
    > li:nth-child(3) {
      grid-area: c;
    }
    > li:nth-child(4) {
      grid-area: d;
    }
    > li:nth-child(5) {
      grid-area: e;
    }
  }
}
section.section-J + svg.wave {
  background-color: base.$light-color;
  * {
    fill: base.$primary-color;
  }
}
// On mobile devices, unset the width of the title wrapper, and set the width and height of the feat-promises element to 40em.
@media (max-width: 768px) {
  section.section-J {
    div.title-wrapper {
      width: unset;
    }
    ul.feat-promises {
      width: 40em;
      height: 40em;
    }
  }
}
