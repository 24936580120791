@use "base";

section.section-I {
  background-color: base.$tertiary-color;
  padding: 4em 4em 5em 4em;
  color: base.$light-color;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  .star {
    width: 5em;
    right: 10%;
    bottom: 10%;
  }
  div.inner {
    display: flex;
    flex-wrap: wrap;
    max-width: 60em;
    gap: 4em;
    div {
    }
    * {
      flex: 1 25em;
    }
    figure {
      display: flex;
      margin: 0;
      align-items: center;
      img {
        max-width: 90%;
      }
    }
  }
  figure {
    img {
      border-radius: 1.5em;
      border: 0.75em solid base.$dark-color;
    }
  }
  button {
    box-shadow: 0 0.25em 0 rgba(255, 255, 255, 1);
    color: base.$light-color;
    font-size: 1em;
    padding: 1em 2em;
    background-color: base.$dark-color;
    border-radius: 2.5em;
  }
  button:hover {
    cursor: pointer;
    box-shadow: none;
    transform: translateY(0.25em);
  }
  h3 {
    font-weight: 900;
    font-size: 4em;
    text-shadow: 0 0.15em 0 rgba(0, 0, 0, 1);
    margin-bottom: 0.5em;
  }
  p {
    font-size: 1.5em;
    line-height: 1.5em;
    margin-bottom: 1em;
  }
  .strong {
    position: relative;
    top: 0.1em;
    height: 1em;
    align-items: flex-end;
    display: inline-flex;
    background-color: base.$primary-color;
    border-radius: 9999px;
    height: 0.5em;
    strong {
      font-weight: 900;
      position: relative;
      top: -0.1em;
    }
  }
}
section.section-I + svg.wave {
  background-color: base.$primary-color;
  * {
    fill: base.$tertiary-color;
  }
}

// When the viewport is more narrow than 450px, change the inner div to be reverse row order.
@media (max-width: 450px) {
  section.section-I div.inner {
    flex-direction: column-reverse;
  }
}
