@use "base";

section.section-G {
  padding: 0 4em 5em 4em;
  display: flex;
  justify-content: center;
  color: base.$light-color;
  background-color: base.$secondary-color;
  .small-star {
    width: 4em;
    top: 20em;
    right: 20em;
  }
  .big-star {
    width: 8em;
    top: 10em;
    right: 10em;
  }
  .inner {
    max-width: 60em;
  }
  h3 {
    font-weight: 900;
    font-size: 4em;
    text-shadow: 0 0.15em 0 rgba(0, 0, 0, 1);
    margin-bottom: 0.5em;
  }
  h4 {
    font-size: 2em;
    margin-top: 0;
  }
  h5 {
    font-size: 1.25em;
    text-shadow: 0 0.15em 0 rgba(0, 0, 0, 1);
  }
  .strong {
    position: relative;
    top: 0.1em;
    height: 1em;
    align-items: flex-end;
    display: inline-flex;
    background-color: base.$primary-color;
    border-radius: 9999px;
    height: 0.5em;
    strong {
      font-weight: 900;
      position: relative;
      top: -0.1em;
    }
  }
  ul.feat-merch {
    margin-top: 4em;
    gap: 6em;
    padding-left: 0;
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
    li {
      min-width: 15em;
      flex: 1;
      img {
        width: 2em;
        height: 2em;
        background-color: black;
        border-radius: 100%;
        border-color: base.$light-color;
        border-width: 0.2em;
        border-style: solid;
        padding: 0.5em;
      }
      h5 {
        margin-top: 1em;
        margin-bottom: 0;
      }
      p {
        font-weight: 300;
      }
    }
  }
}
section.section-G + svg.wave {
  background-color: base.$light-color;
  * {
    fill: base.$secondary-color;
  }
}
