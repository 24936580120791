@use "base";

section.section-F {
  padding-top: 10em;
  padding-bottom: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: base.$light-color;
  background-color: #000;
  // Set a repeating background image.
  background-image: url("../assets/img/purple-wiggle-rotated.svg");
  background-repeat: repeat;
  background-size: 20em auto;
  .strong {
    position: relative;
    top: 0.1em;
    height: 1em;
    align-items: flex-end;
    display: inline-flex;
    background-color: base.$secondary-color;
    border-radius: 9999px;
    height: 0.5em;
    strong {
      position: relative;
      top: -0.1em;
      filter: drop-shadow(0 0.1em 0 rgba(0, 0, 0, 1));
    }
  }
  h3 {
    font-size: 3em;
  }
  ul.feat-requirements {
    padding: 0;
    max-width: 60em;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    li {
      box-shadow: 0 0.5em 0 base.$tertiary-color;
      background-color: #fff;
      color: #000;
      padding: 0.5em 1em;
      margin: 1em;
      border-radius: 9999px;
    }
  }
}
section.section-F + svg.wave {
  background-color: base.$secondary-color;
  * {
    fill: black;
  }
}
